// import { useEffect, useState } from "react";
require('dotenv').config()

const alchemyBaseUrl = process.env.REACT_APP_ALCHEMY_BASE_URL;
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const apiBaseUrl = process.env.REACT_APP_API_URL;

// Production:
// const baseUrl = `https://polygon-mainnet.g.alchemy.com/v2/${alchemyKey}`

// Development:
const baseUrl = `${alchemyBaseUrl}/${alchemyKey}`;

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(baseUrl);


export const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "👆🏽 Write a message in the text-field above.",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentNFTs = async (address, stores) => {
  // console.log("Function: getCurrentNFTs");


  // const contractAddr = "0xcad397c9B571a61A818A6B83566Bf472ABE9b3C0";
  // const contractAddr = "0xd24c77112Bb89809a3cC28F432ac030447D8432C";
  const contractAddr = null;
  // Print owner's wallet address:
  // const ownerAddr = "0x645DC92D544483613dbbE05bA19A459E02039003";
  const ownerAddr = address;
  console.log("fetching NFTs for address:", ownerAddr);
  console.log("...");
  const transactionData = await getAddressNFTTransactions(baseUrl, ownerAddr, [], contractAddr)
  console.log("transactionData: ", transactionData);
  const nftData = await getAddressNFTs(baseUrl, ownerAddr, [], "", contractAddr)
  
  console.log("nftData: ", nftData);
  const sortedLists = await sortNfts(nftData, transactionData, contractAddr, stores)
  return sortedLists
};

export const getAddressNFTs = async (endpoint, owner, nfts, pagekey, contractAddress, retryAttempt) => {
  if (retryAttempt === 5) {
    return "There was an error getting users NFTS";
  }
  if (owner) {
      let data;
      try {
          if (contractAddress) {
              data = await fetch(`${endpoint}/getNFTs?owner=${owner}&contractAddresses[]=${contractAddress}`).then(data => data.json())
          } else {
            if (pagekey !== ""){
              // data = await fetch(`${endpoint}/v1/getNFTs?owner=${owner}`).then(data => data.json())
              data = await fetch(`${endpoint}/getNFTs?owner=${owner}&pageKey=${pagekey}`).then(data => data.json())
              if (data.pageKey) {
                let new_data = await getAddressNFTs(endpoint, owner, data.ownedNfts, data.pageKey)
                data.ownedNfts = data.ownedNfts.concat(new_data.ownedNfts)
              }

            } else {
              data = await fetch(`${endpoint}/getNFTs?owner=${owner}`).then(data => data.json())
              if (data.pageKey) {
                let new_data = await getAddressNFTs(endpoint, owner, data.ownedNfts, data.pageKey)
                data.ownedNfts = data.ownedNfts.concat(new_data.ownedNfts)
              }
            }
          }
          // console.log("GETNFTS: ", data)
      } catch (e) {
          getAddressNFTs(endpoint, owner, contractAddress, retryAttempt+1)
      }

      return data
  }
}
export const getAddressNFTTransactions = async (endpoint, owner, nfts, contractAddress, retryAttempt) => {
  if (retryAttempt === 5) {
      return "There was an error getting users NFT Transactions";
  }
  if (owner) {
      let data;
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var new_array = []
        new_array.push(contractAddress)
        console.log(new_array)
        var raw = JSON.stringify({
          "jsonrpc": "2.0",
          "id": 0,
          "method": "alchemy_getAssetTransfers",
          "params": [
            {
              "fromBlock": "0x0",
              "fromAddress": "0x0000000000000000000000000000000000000000",
              "toAddress": owner,
              "excludeZeroValue":true,
              "category": ["erc721","erc1155"]
            }
          ]
        });
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          data = await fetch(`${endpoint}`, requestOptions).then(data => data.json())
          console.log(data)
      } catch (e) {
          getAddressNFTs(endpoint, owner, nfts, contractAddress, retryAttempt+1)
      }

      return data
  }
}


export const sortNfts = async (nftResponse, nftTransactions, contractAddr, stores) => {
  let nfts = {
    certifyNFTs: [],
    otherNFTs: []
  }
  nftResponse.ownedNfts.forEach(element => {
    nftTransactions.result.transfers.forEach(transaction => {
      if (element.id.tokenId === transaction.tokenId && element.contract.address === transaction.rawContract.address){
        element.transaction_id = transaction.hash
        element.modal = false
        // nfts.certifyNFTs.push(element)
        nfts.certifyNFTs.push(element)
      }
    })
    // if (stores.contract_addresses.includes(element.contract.address)){
    //   nfts.certifyNFTs.push(element)
    // }else{
    //   nfts.otherNFTs.push(element)
    // }
  });
  console.log(nfts)

  return nfts
}

export const getStores = async(wallet_address) => {
  let data;
  console.log(wallet_address)
  try {
    data = await fetch(`${apiBaseUrl}/products/nft_products?wallet_address=${wallet_address}`).then(data => data.json())
  } catch (e) {
    data = {
      "contract_addresses": [],
      "stores": []
    }
  }
  return data
}

export const getDiscountsForStores = async(stores) => {
	// for (let store of stores.stores){
	// 	console.log(store)
	// 	let data;
	// 	try {
	// 		// var myHeaders = new Headers();
    //     	// myHeaders.append("Content-Type", "application/json");
    //     	// myHeaders.append("Content-Type", "shpua_93b1aeae52a9834badd5fb457ad9b1dc");
	// 		// var requestOptions = {
	// 		// 	method: 'GET',
	// 		// 	headers: myHeaders,
	// 		// };
	// 		// console.log("here")
	// 		// data = await fetch(`${store.store_url}/admin/api/2022-07/price_rules.json`, requestOptions).then(data => data.json())
	// 		data = await fetch(`http://localhost:3002/products/nft_products?shop=${wallet_address}`).then(data => data.json())
	// 		console.log(data)
	// 	} catch (e) {
	// 		data = {"error": "no rewards"}
	// 		console.log(data)
	// 	}
	// }
	let data;
	try {
		data = await fetch(`${apiBaseUrl}/stores/get_store_discounts?stores=1`).then(data => data.json())
		console.log('data: /stores/get_store_discounts?stores=1', data);
	} catch (e) {
		data = {"error": "no rewards"}
		console.log(data)
	}
	if (data.stores){
		stores.stores.forEach(store => {
			console.log('store: ', store);
			console.log("discounts")
			data.stores.forEach(systemstore => {
				if (store.id === systemstore.store_id){
					console.log("System store: ", systemstore);
					store.discounts = systemstore.discounts
				}
			})
		})
	}
	return stores
}

export const createQRCode = async(transactionId) => {
  // let url = "www.google.com"
  // QRcode.toDataURL(url, {
  //   width:800,
  //   margin:1
  // }, (err, url) => {
  //   if (err) return console.error(err)
  //   console.log(url)
  //   return url
  // })
  let productId = '8';
  let data;
  try {
    data = await fetch(`${apiBaseUrl}/products/get_qrcode?transaction_id=${transactionId}`).then(data => data.json())
    // console.log(data)
    let content = Buffer.from(data.qr).toString('base64')
    // console.log(content)
    let image = `data:image/png;base64,${content}`
    // console.log(image)
    var a = document.createElement("a");
    a.href = image;
    a.download = data.object_key;
    a.click();

    // Open the image in a new tab:
    // window.open(data.url, '_blank');

	} catch (e) {
		let data = {"error": "no rewards"}
		console.log(data)
	}

}
// import { useState } from "react";
import './header.css';
// import Button from 'react-bootstrap/Button';
// import Offcanvas from 'react-bootstrap/Offcanvas';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import logo from '../../assets/certify-dc-white.svg'; // with import
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Sidebar = (props) => {
    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    
    return (
        <>
            <div className="desktop-header" style={{background: "#53168E", height: "95px", width: "100%"}}>
                <Row xs={2} style={{height: "100%"}}>
                    <Col xs={4} style={{textAlign: "left"}}>
                        <img alt='Certify Logo' src={logo} className="certify-header-logo" />
                    </Col>
                    <Col xs={8} style={{textAlign: "right"}}>
                        <div style={{display:"flex", color: "white", height: "100%", paddingTop: "26px", float: "right"}}>
                            <button id="walletButton" onClick={props.fn_connectWalletPressed}>
                                {props.att_walletAddress.length > 0 ? (
                                    "Connected: " +
                                    String(props.att_walletAddress).substring(0, 6) +
                                    "..." +
                                    String(props.att_walletAddress).substring(38)
                                ) : (
                                    <span>Connect Wallet</span>
                                )}
                            </button>
                        </div>
                        <div style={{display:"flex", color: "white", height: "100%", paddingTop: "36.4px", marginRight: "35px", float: "right", cursor: "pointer"}}><span>My Collection</span></div>
                        <div style={{display:"flex", color: "white", height: "100%", paddingTop: "36.4px", marginRight: "35px", float: "right", cursor: "pointer"}}><span>Home</span></div>
                    </Col>
                </Row>
            </div>
            <div className="mobile-header" style={{background: "#53168E", height: "95px", width: "100%"}}>
                <Navbar style={{background: "#53168E", color: "white"}} expand="lg">
                    <Container>
                        <Navbar.Brand href="#home" style={{height: "95px"}}><img alt='Certify Logo' src={logo} style={{marginTop: "27px", height: "40px"}} /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="https://certifydc.com/">Home</Nav.Link>
                                <Nav.Link href="/">My Collection</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            <button className="walletButtonMobile" id="walletButton" onClick={props.fn_connectWalletPressed}>
                    {props.att_walletAddress.length > 0 ? (
                        "Connected: " +
                        String(props.att_walletAddress).substring(0, 6) +
                        "..." +
                        String(props.att_walletAddress).substring(38)
                    ) : (
                        <span>Connect Wallet</span>
                    )}
                </button>
            {/* <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>Home</div>
                    <div>Rewards</div>
                </Offcanvas.Body>
            </Offcanvas> */}
        </>
    );
};
  
export default Sidebar;
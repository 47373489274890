import React from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import polygon_icon from '../../assets/polygonmatic-white.svg';
import magnifier_icon from '../../assets/zoom-in-white.svg';
import opensea_icon from '../../assets/opensea-white.svg';
import qr_code_icon from '../../assets/qr_code_white.svg';

const NFTList = (props) => {
	const {
		fn_getQRCode
	} = props;
	const baseOpenSea = process.env.REACT_APP_URL_OPENSEA;
	const basePolygonScan = process.env.REACT_APP_URL_POLYGONSCAN;

	if(props.nfts.length === 0){
		return(<></>)
    }else{
		return (
			props.nfts.map((element,index)=>{
				if(props.store.product_token_ids.includes(element.transaction_id)){
					return(
						<Col key={index}>
							<Card key={index}>
								<Card.Img variant="top" src={element.media[0].gateway} />
								<Card.Body style={{borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px", textAlign: "left"}}>
								<Card.Title style={{fontSize: "15px", fontWeight: "bold", color:"rgba(255,255,255,1)"}}>{element["title"]}</Card.Title>
								<Card.Text style={{fontSize: "13px", color:"rgba(255,255,255,0.7)"}}>
									{/* <button></button> */}
									
									<a className="mobile-nft-image-link" target="_blank" rel="noreferrer" href={element.media[0].gateway}>
										<img alt="Magnifier icon" src={magnifier_icon} style={{width: "24px", height: "24px", marginRight: "24px", cursor: "pointer"}}></img>
									</a>
									
									<img className="desktop-nft-image-link" onClick={() => props.fn_toggleModal(element, index)} alt="Magnifier icon" src={magnifier_icon} style={{width: "24px", height: "24px", marginRight: "24px", cursor: "pointer"}}></img>
									
									<a target="_blank" rel="noreferrer" href={`${baseOpenSea}/${element.contract.address}/${parseInt(element.id.tokenId)}` }>
										<img alt="Opensea.io icon" src={opensea_icon} style={{width: "24px", height: "24px", marginRight: "24px"}}></img>
									</a>
									
									<a target="_blank" rel="noreferrer" href={`${basePolygonScan}/${element.transaction_id}`}>
										<img alt="Polygon icon" src={polygon_icon} style={{width: "24px", height: "24px", marginRight: "24px"}}></img>
									</a>

									<img alt="Polygon icon" src={qr_code_icon} style={{width: "24px", height: "24px", marginRight: "24px", cursor: "pointer"}} onClick={() => {fn_getQRCode(element.transaction_id)}}></img>

								</Card.Text>
								{/* <Button variant="primary">Go somewhere</Button> */}
								</Card.Body>
							</Card>
							<Modal fullscreen={true} show={element.modal}>
								<Modal.Body className="ModalImageBody">
									<Button className="ModalClosebtn" variant="secondary" onClick={() => props.fn_toggleModal(element, index)}>
										X
									</Button>
									{/* <ZoomSlider slides={[<img alt="NFT image" src="https://cdn.shopify.com/s/files/1/0602/2417/6278/products/Q1-Design-logo-Black-12x.png?v=1645059533" />,<img alt="NFT image" src="https://cdn.shopify.com/s/files/1/0602/2417/6278/products/Q1-Design-logo-Black-12x.png?v=1645059533" />]}/> */}
									<img style={{height: "auto",width: "auto", maxHeight: "100%", maxWidth: "100%"}} alt="NFT Token" src={element.media[0].gateway}></img>
								</Modal.Body>
							</Modal>
						</Col>
					)
				}else{
					return(<></>)
				}
			})
		)
	}

}

export default NFTList;
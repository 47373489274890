import './App.css';
import Minter from './pages/myCollection/Index'

function App() {
  return (
    <div className="App" style={{minHeight: "100vh", background: "#171717"}} >
      <Minter></Minter>
    </div>
  );
}

export default App;

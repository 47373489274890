import React, { useEffect, useState } from "react";
import { 
  connectWallet,
  getCurrentWalletConnected, //import here
  getCurrentNFTs,
  getStores,
  getDiscountsForStores,
  createQRCode
} from "./utils/interact.js";
import Row from 'react-bootstrap/Row';
import emptyStateImg from '../../assets/nft-claimed-graphic-dc.png'; // with import
import Header from '../../components/Header/header'
import ConnectYourWallet from "../../components/ConnectYourWallet";

import StoreList from "./storeList.js";

const Minter = (props) => {

	//State variables
	const [walletAddress, setWallet] = useState("");
	const [status, setStatus] = useState("");
	const [nfts, setNFTS] = useState([]);
	const [stores, setStores] = useState([]);
	const [certifyNFTCount, setCertifyNFTCount] = useState(0);
	const [errors, setErrors] = useState([]);
	const [pageCss, setPageCss] = useState("page");
 
	useEffect(() => {
		async function check() {
			const {address, status} = await getCurrentWalletConnected();
			
			// Set wallet address using a variable:
			let walletAddress = address;

			setWallet(walletAddress);
			setStatus(status);
			
			
			addWalletListener();
			if (walletAddress !== "") {
				getStoreNFTS(walletAddress)
			}
		}
		// console.log("hi1")
		check()
	}, []);

	const connectWalletPressed = async () => {
		let navigatorAgent = navigator;

		if (typeof window.ethereum !== 'undefined') {
			const walletResponse = await connectWallet();
			setErrors(walletResponse.status)
			setStatus(walletResponse.status);
			setWallet(walletResponse.address);
			if (walletResponse.address !== ""){
				getStoreNFTS(walletResponse.address);
			}
		} else {
			// Checkif using iPhone:
			if (['iPhone', 'Linux armv81'].includes(navigatorAgent.platform) || navigatorAgent.maxTouchPoints > 0) {
				let tempUrl = `https://metamask.app.link/dapp/${window.location.hostname}`;
				const a = document.createElement("a");
				a.href = tempUrl;
				a.target = "_blank";
				document.body.appendChild(a);
				a.click();
				a.remove();
				return;
			} else {
				alert("Please install Metamask in your browser.");

			}
		}

	};

	const toggleModal = (element, index) => {
			console.log("hi")
			console.log(index)
			console.log(nfts)
			console.log(nfts)
			console.log(nfts[index])
			nfts[index].modal = !element.modal
			if (element.modal === true){
				setPageCss("page-modal")
			}else{
				setPageCss("page")
			}
			setNFTS([...nfts])

	}

	const getStoreNFTS = async (address) => {
		console.log('status: ', status);
		console.log('errors: ', errors);
		const stores = await getStores(address)
		// const stores = await getStores("0x645DC92D544483613dbbE05bA19A459E02039003");
		// stores.map(store => (console.log(store)))
		if (stores.message !== "Products do not exist."){
			const nfts = await getCurrentNFTs(address, stores);
			setNFTS(nfts.certifyNFTs)
			console.log(stores)
			// setErrors(nfts)
			if (stores){
				let store;
				for( store of stores.stores){
				store.tab = "Tokens"
				}
				setStores(stores.stores)
				setCertifyNFTCount(stores.certify_NFT_count)
				console.log(stores)
				const data = await getDiscountsForStores(stores)
				console.log('data: getDiscountsForStores ', data);
				setStores(data.stores)
			}
		}
	}

	function onChangeWalletAddress(address) {
		setWallet(address);
	}

	const handleSubmitWalletAddress = (event) => {
		// console.log('handleSubmitWalletAddress:::2022-07-13');
		// console.log("event:::2022-07-13", event);
		// console.log("event.target:::2022-07-13", event.target);
		// console.log("event.target.pasteWalletAddress.value:::2022-07-13", event.target.pasteWalletAddress.value);
		setWallet(event.target.pasteWalletAddress.value);
		event.preventDefault();
		getStoreNFTS(walletAddress)
	}


	function addWalletListener() {
		if (window.ethereum) {
			window.ethereum.on("accountsChanged", (accounts) => {
			console.log("state changed")
			if (accounts.length > 0) {
				setWallet(accounts[0]);
				setStatus("👆🏽 Write a message in the text-field above.");
			} else {
				setWallet("");
				setStatus("🦊 Connect to Metamask using the top right button.");
			}
			});
		} else {
			setStatus(
			<p>
				{" "}
				🦊{" "}
				<a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
				You must install Metamask, a virtual Ethereum wallet, in your
				browser.
				</a>
			</p>
			);
		}
	}

	function showTokens(index){
		stores[index].tab = "Tokens"
		setStores([...stores])
	}

	function showRewards(index){
		stores[index].tab = "Rewards"
		setStores([...stores])
	}

	function activateReward(store, discount, discount_index){
		console.log(stores)
		console.log(store)
		console.log(discount_index)
		stores.forEach((element,index) => {
			console.log(element)
			if (element.id === discount.store_id){
				if (element.discounts[discount_index].terms_accept && element.discounts[discount_index].terms_accept == true){
					if (discount.once_per_customer == true){
						element.discounts[discount_index].redeemable = true
					}else{
						element.discounts[discount_index].activated_by.push(walletAddress)
					}
				}else{
					element.discounts[discount_index].activation_error = "Please accept the Terms and Conditions to Activate this reward."
					element.discounts[discount_index].activation_checkbox_error = true
					element.discounts[discount_index].activation_show_error = true
				}
			}
		})
		setStores([...stores])
	}

	function RedeemReward(store, discount, discount_index){
		console.log(stores)
		console.log(store)
		console.log(discount_index)
		stores.forEach((element,index) => {
			console.log(element)
			if (element.id === discount.store_id){
				element.descounts[discount_index].redeemable_Date = Date.now
				element.discounts[discount_index].activated_by.push(walletAddress)
			}
		})
		setStores([...stores])
	}

	function closeRewardErrorModal(store, discount, discount_index){
		stores.forEach((element,index) => {
			if (element.id === discount.store_id){
				element.discounts[discount_index].activation_show_error = false
			}
		})
		setStores([...stores])

	}

	const accept_terms_and_conditions = (event, discount, discount_index) => {
		console.log("it was changed")
		console.log(event)
		stores.forEach((element,index) => {
			console.log(element)
			if (element.id === discount.store_id){
				if (event.target.checked == true){
					element.discounts[discount_index].terms_accept = true
				}else{
					element.discounts[discount_index].terms_accept = false
				}
			}
		})
		setStores([...stores])
	}

if (walletAddress === ''){
	return(
		<>
			<ConnectYourWallet
				connectWalletPressed={connectWalletPressed}
				handleSubmitWalletAddress={handleSubmitWalletAddress}
				onChangeWalletAddress={onChangeWalletAddress}
				walletAddress={walletAddress}
			></ConnectYourWallet>
		</>
	)
}

return (
	<div className={pageCss}>
		<Header
			fn_connectWalletPressed={connectWalletPressed}
			att_walletAddress={walletAddress}
		></Header>
		<div className="Minter">
			{/* <div>
				<span>{errors}</span>
			</div> */}
			<div style={{color: "white", fontSize: "36px"}}>My Collection</div>

			<br></br>
			{stores.length === 0 ? (
				<div style={{textAlign: "center"}}>
					<div>
						<span style={{color: "white", fontSize: "20px"}}>You don't own any Certify DCs yet.</span>
					</div>
					<div>
						<span style={{color: "white", fontSize: "15px"}}>Start collecting Certify DCs from select online stores to view them here.</span>
					</div>
					<img alt="No NFTs" src={emptyStateImg} className="empty-image"></img>
				</div>
			) : (
				<div>
					<Row xs={1} md={1}>
					{ stores.length !== 0 && (
						<StoreList 
							var_stores={stores}
							var_nfts={nfts}
							var_wallet_address={walletAddress}
							var_certify_nft_count={certifyNFTCount}
							fn_showTokens={showTokens}
							fn_showRewards={showRewards}
							fn_toggleModal={toggleModal}
							fn_activateReward={activateReward}
							fn_getQRCode={createQRCode}
							fn_accept_terms_and_conditions={accept_terms_and_conditions}
							fn_closeRewardErrorModal={closeRewardErrorModal}
						/>
					)}
					</Row>
				</div>
			)}
		</div>
	</div>
  );
};

export default Minter;

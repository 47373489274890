import React, { useEffect} from "react";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import store_logo from '../../assets/certify-logo-1026x1026.png';

import NFTList from "./nftList.js";
import RewardsList from "./rewardsList";

const StoreList = (props) => {
    useEffect(() => {
        async function check() {
            console.log("StoreList Refreshed")
        }
        check()
    }, props.var_stores);
    if(props.var_stores.length === 0){
        return(<></>)
    }else{
        return(
            props.var_stores.map((element,index) => {
                return(
                    <div key={index} id="storelist">
                        <div id="storeListHeader">
                            <img alt="Store Logo" src={store_logo} style={{width: "48px", height: "48px", borderRadius: "100%"}}></img>
                            <span style={{color: "white", fontSize: "24px", marginLeft: "12px", fontWeight: "bold"}}>{element.company}</span>
                        </div>
                        <div id="storeListTabButtons">
                            <button className={`my-token-button ${element.tab === "Tokens" ? "active" : ""}`} onClick={function(e) {props.fn_showTokens(index)}}>
                                <span>My Tokens</span>
                            </button>
                            <button className={`rewards-button ${element.tab === "Rewards" ? "active" : ""}`} onClick={function(e) {props.fn_showRewards(index)}}>
                                <span>Available Rewards</span>
                            </button>
                        </div>
                        { element.tab === "Tokens" && props.var_nfts.length !== 0 && (
                            <div style={{textAlign:"center"}}>
                                <Row xs={1} sm={3} lg={5} className="g-4">
                                    <NFTList 
                                        nfts={props.var_nfts}
                                        store={element}
                                        fn_toggleModal={props.fn_toggleModal}
                                        fn_getQRCode={props.fn_getQRCode}
                                    />
                                </Row>
                                <button style={{backgroundColor: "rgba(0,0,0,0)", borderRadius: "27px", marginTop: "60px", display: "none"}}>
                                    <span>View all items</span>
                                </button>
                            </div>
                        )}
                        { element.tab === "Rewards" && (
                            <div>
                                <Card className="rewardsCard">
                                    <Card.Body style={{borderRadius: "25px"}}>
                                        {/* <Card.Text style={{fontSize: "13px", color:"rgba(255,255,255,0.7)"}}>
                                            Rewards
                                        </Card.Text> */}
                                        <RewardsList
                                            var_stores={props.var_stores}
                                            var_store={element}
                                            var_certify_nft_count={props.var_certify_nft_count}
                                            var_wallet_address={props.var_wallet_address}
                                            fn_activateReward={props.fn_activateReward}
                                            fn_accept_terms_and_conditions={props.fn_accept_terms_and_conditions}
                                            fn_closeRewardErrorModal={props.fn_closeRewardErrorModal}
                                        />
                                    </Card.Body>
                                </Card>
                            </div>
                        )}
                    </div>
                )
            })
        )
    }
}

export default StoreList;
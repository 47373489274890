import React, { isValidElement, useEffect, useState } from "react";
// import Card from 'react-bootstrap/Card';
// import CardGroup from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import reward_money_icon from '../../assets/reward-money-icon.svg';
import reward_money_icon_disabled from '../../assets/reward-money-icon-desabled.svg';
import copy_clipboard from '../../assets/content_copy_black.svg';

const RewardsList = (props) => {

    useEffect(() => {
        async function check() {
            console.log("RewardsList Refreshed")
            console.log(props)
        }
        check()
    }, props.var_stores);


    const checkQualify = (store, index) => {
        console.log("Checking Qualification")
        let discount = store.discounts[index]

        if (discount.nfts_from_own_store == true){
            if (discount.amount_of_nfts_needed){
                if (store.products.length === discount.amount_of_nfts_needed){
                    return true
                }
            }
        }else if (discount.nfts_from_own_store == false){
            if (discount.amount_of_nfts_needed){
                if (props.var_certify_nft_count === discount.amount_of_nfts_needed){
                    return true
                }
            }
        }
    }
    const formatDate = (date) => {
        date = new Date(date)
        var month = date.getMonth()
        var day = date.getDate()
        var year = date.getFullYear()
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var monthString = ""
        switch(month){
            case 1:
                monthString = "Jan"
                break;
            case 2:
                monthString = "Feb"
                break;
            case 3:
                monthString = "Mar"
                break;
            case 4:
                monthString = "Apr"
                break;
            case 5:
                monthString = "May"
                break;
            case 6:
                monthString = "Jun"
                break;
            case 7:
                monthString = "Jul"
                break;
            case 8:
                monthString = "Aug"
                break;
            case 9:
                monthString = "Sep"
                break;
            case 10:
                monthString = "Oct"
                break;
            case 11:
                monthString = "Nov"
                break;
            case 12:
                monthString = "Dec"
                break;
        }

        var dateString = `${monthString} ${day}, ${year} at ${hours}:${minutes}`
        return dateString
    }

    const copy_to_clipboard = (code) => {
        navigator.clipboard.writeText(code);

        alert("Copied to Clipboard")
    }



	if(props.var_store.discounts.length === 0){
		return(<></>)
    }else{
		return (
            // <></>
			props.var_store.discounts.map((element,index)=>{
			// 	if(props.store.product_token_ids.includes(element.transaction_id)){
			// 		return(
            //             <></>
			// 		)
			// 	}else{
                // 	}
                let result = checkQualify(props.var_store, index)
                if (result === true){
                    return(
                        <Row key={index} style={{padding: "40px 30px", background: "#1C1C1C", marginBottom: "10px", borderRadius: "24px"}}>
                            <Col md={1}>
                                <img alt="Reward Icon" src={reward_money_icon} style={{height: "90px", width: "90px"}}></img>
                            </Col>
                            <Col md={4}>
                                <div className="Reward-Header">Description</div>
                                <div className="Reward-Text-Description">{element.title}</div>
                            </Col>
                            <Col md={3}>
                                <div className="Reward-Header">Participating store</div>
                                <div className="Reward-Text">{element.third_party_store === true ? element.third_party_store_name : props.var_store.company}</div>
                                <div className="Reward-Header">Expiry</div>
                                <div className="Reward-Text">{element.ends_at ? formatDate(element.ends_at) : "No Expiry"}</div>
                                {/* <div>{element.ends_at ? "Single User" : ""}</div> */}
                            </Col>
                            <Col md={2}>
                                <div className="Reward-Header">Unlock Criteria</div>
                                <div className="Reward-Text">{element.nfts_from_own_store === true ? props.var_store.store_NFT_count > element.amount_of_nfts_needed ? element.amount_of_nfts_needed : `Store DCs: ${props.var_store.store_NFT_count}` : props.var_certify_nft_count > element.amount_of_nfts_needed ? element.amount_of_nfts_needed : `Certify DCs: ${props.var_certify_nft_count}`}/{element.amount_of_nfts_needed}</div>
                            </Col>
                            <Col md={2}>
                                {(element.activated_by.includes(props.var_wallet_address)) ? 
                                <div>
                                    <div className="Reward-Header">Reward Code</div>
                                    <div className="Reward-Code-Text">{element.reward_code} <img onClick={() => copy_to_clipboard(element.reward_code)} alt="copy to clipboard" src={copy_clipboard} style={{height: "19px", width: "22px"}}></img></div>
                                </div>
                                :
                                <>
                                    <span className="Reward-Text"><input className="accept_terms_checkbox" type="checkbox" onChange={(event) => props.fn_accept_terms_and_conditions(event, element, index)}></input><p style={{fontSize: "15px", lineHeight: "20px"}}>I accept the Terms and Conditions</p></span>
                                    <div style={{width: "100%", display: "flex",justifyContent: "center", alignItems: "center"}}>
                                        <Button onClick={() => props.fn_activateReward(props.var_store, element, index)} className="RewardActivateBtn">Activate</Button>
                                    </div>
                                    <div style={{width: "100%", display: "flex",justifyContent: "center", alignItems: "center"}}>
                                        <Button onClick={() => props.fn_activateReward(props.var_store, element, index)} className="RewardActivateBtn">Redeem</Button>
                                    </div>
                                </>
                                }
                                {/* <div><Button className="RewardActivateBtn">Activate</Button></div>
                                <div>
                                    <div className="Reward-Header">Reward Code</div>
                                    <div className="Reward-Text">{element.reward_code}</div>
                                </div> */}
                            </Col>
                            <Modal centered show={element.activation_show_error}  onHide={() => props.fn_closeRewardErrorModal(props.var_store, element, index)}>
								<Modal.Body style={{background: "white", textAlign: "center", padding: "30px 20px"}}>
									<span className="ModalClosebtn" onClick={() => props.fn_closeRewardErrorModal(props.var_store, element, index)}>
										X
									</span>
                                    <span style={{color: "black", marginBottom: "10px", display: "block"}}>{element.activation_error}</span>
                                    <Button className="AcceptBtn" onClick={() => props.fn_closeRewardErrorModal(props.var_store, element, index)}>
										Got it!
									</Button>
								</Modal.Body>
							</Modal>
                            <Modal centered show={element.activation_show_error}  onHide={() => props.fn_closeRewardErrorModal(props.var_store, element, index)}>
								<Modal.Body style={{background: "white", textAlign: "center", padding: "30px 20px"}}>
									<span className="ModalClosebtn" onClick={() => props.fn_closeRewardErrorModal(props.var_store, element, index)}>
										X
									</span>
                                    <span style={{color: "black", marginBottom: "10px", display: "block"}}>{element.activation_error}</span>
                                    <Button className="AcceptBtn" onClick={() => props.fn_closeRewardErrorModal(props.var_store, element, index)}>
										Got it!
									</Button>
								</Modal.Body>
							</Modal>
                        </Row>
                    )
                }else{
                    return(
                        <Row key={index} style={{padding: "40px 30px", background: "#1C1C1C", marginBottom: "10px", borderRadius: "24px"}}>
                            <Col md={1}>
                                <img alt="Reward Icon" src={reward_money_icon_disabled} style={{height: "90px", width: "90px"}}></img>
                            </Col>
                            <Col md={4}>
                                <div className="Reward-Header">Description</div>
                                <div className="Reward-Text-Description disabled">{element.title}</div>
                            </Col>
                            <Col md={3}>
                                <div className="Reward-Header">Participating store</div>
                                <div className="Reward-Text">{element.third_party_store === true ? element.third_party_store_name : props.var_store.company}</div>
                                <div className="Reward-Header">Expiry</div>
                                <div className="Reward-Text disabled">{element.ends_at ? formatDate(element.ends_at) : "No Expiry"}</div>
                            </Col>
                            <Col md={2}>
                                <div className="Reward-Header">Unlock Criteria</div>
                                <div className="Reward-Text disabled">{element.nfts_from_own_store === true ? props.var_store.store_NFT_count > element.amount_of_nfts_needed ? element.amount_of_nfts_needed : `Store DCs: ${props.var_store.store_NFT_count}` : props.var_certify_nft_count > element.amount_of_nfts_needed ? element.amount_of_nfts_needed : `Certify DCs: ${props.var_certify_nft_count}`}/{element.amount_of_nfts_needed}</div>
                            </Col>
                            <Col md={2}>
                                {(element.activated_by.includes(props.var_wallet_address)) ? 
                                <div>
                                    <div className="Reward-Header">Reward Code</div>
                                    <div className="Reward-Text">{element.reward_code}</div>
                                </div>
                                :
                                <>
                                    <span className="Reward-Text disabled"><input className="accept_terms_checkbox" type="checkbox" disabled onChange={(event) => props.fn_accept_terms_and_conditions(event, element, index)}></input><p style={{fontSize: "15px", lineHeight: "20px"}}>I accept the Terms and Conditions</p></span>
                                    <div style={{width: "100%", display: "flex",justifyContent: "center", alignItems: "center"}}>
                                        <Button disabled className="RewardLockedBtn">Locked</Button>
                                    </div>
                                </>
                                }
                            </Col>
                        </Row>
                    )
                }
			})
		)
	}

}

export default RewardsList;